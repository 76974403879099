<template>
    <div class="apiAuthorization">
		<!-- 设置API -->
		<van-nav-bar
		    :title="$t('system.APIAuthorization')"
		    left-arrow
		    @click-left="onClickLeft"
			:right-text="$t('system.helpGuide')"
			@click-right="onclickRight"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="content g_safe_top">
			<div class="apiList bgWhite">
			    <ul>
                    <li v-for="(item,index) in exchangeList" :key="index"
                    @click="btnChange({
                        id: item.id, 
                        name: item.name_py
                    })">
                        <div class="leftBox">
                            <div class="img-icon" :class="{hasBg:item.name_py=='okex'}">
			                    <img :src="require('@/assets/imgs/new_color/api-'+item.name_py+'.png')">
                            </div>
                            <span>{{
                                item.name_py=='huobi'?$t('system.huobi'):
                                item.name_py=='bian'?$t('system.bian'):
                                item.name_py=='okex'?$t('system.OKEx'):item.name_py.toUpperCase()
                            }}</span>
                        </div>
			            <div class="spot"><img src="@/assets/imgs/icon/menu-spot.png"></div>
			        </li>
			    </ul>
			</div>
			
			<van-action-sheet
			    v-model="show"
			    :actions="actions"
			    :cancel-text="$t('common.cancel')"
			    close-on-click-action
			    @select="onSelect"
			/>
		</div>
        
    </div>
</template>

<script>
	import { mapGetters } from 'vuex'
    export default {
		name: 'apiAuthorization',
		computed: {
			...mapGetters([
				'exchangeList',
			]),
		},
        data() {
            return {
                show: false,
                // apiDataList: [],
                exchange_id: "",
                exchange_name: "",
                actions: [
                    {name:  ""},
                    {name: ""},
                ],
            }
        },
        created() {
			this.actions[0].name = this.$t('system.SetAPI');
			this.actions[1].name = this.$t('system.DeleteAPI');
            // this.getAPIDataList();
        },
        methods: {
			onClickLeft() {
				this.$router.back(-1);
			},
			onclickRight() {
                this.$router.push({
                    name:'consultingService',
                    params:{
                        active:'2',
                    },
                })
			},
            // getAPIDataList() {
            //     this.$post2("Trade/Api/Index/getApiSet").then((res) => {
            //         this.apiDataList = res.list;
            //     });
            // },
            btnChange(item) {
                this.exchange_id = item.id;
                this.exchange_name = item.name;
                this.show = true;
            },
            onSelect(item) {
                this.show = false;
                if (item.name == this.$t('system.DeleteAPI')) {
                    this.$dialog.confirm({
                        title: this.$t('system.Delete'),
                        message: this.$t('system.sure_to_delete'),
                    }).then(() => {
                        this.$post2("Trade/Api/Index/delApiKey", {
                            exchange_id: this.exchange_id,
                        }).then(() => {
                            this.$toast({message: this.$t('system.delete_ok'),}
                            );
                        });
                    }).catch(() => {
                        this.$toast({message: this.$t('system.delete_no'),});
                    });
                } else if (item.name == this.$t('system.SetAPI')) {
                    this.$router.push({
                        path: "/viewAPI2",
                        query: {
                            exchange_id: this.exchange_id,
                            exchange_name: this.exchange_name,
                        },
                    });
                }
            },
        }
    };
</script>

<style lang="less" scoped>
    .apiAuthorization {
		font-family: PingFangSC-Regular;
        height: 100%;
        .content{
            height: 100%;
        }
        .apiList {
            margin: 0.2rem 0;
			margin-bottom: 0;
			// min-height:calc(91.8vh + 54px);
			border-top-left-radius: 0.32rem;
			border-top-right-radius: 0.32rem;
			height: calc(100% - 0.2rem);

            li {
                padding: 0.2rem 0;
				margin: 0 0.4rem;
                border-bottom: 1px solid #F1F1F1;
                overflow: hidden;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .leftBox{
                    display: flex;
                    align-items: center;
                    .img-icon {
                        width: 0.61rem;
                        height: 0.61rem;
                        margin-right: 0.22rem;
                        // vertical-align: -0.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.hasBg img{
                            width: 70%;
                            height: 70%;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }

                }

                .spot {
                    // float: right;

                    img {
                        width: 0.28rem;
                    }
                }

                span {
                    font-size: 0.25rem;
                    color: #02172E;
                }
            }

            li:last-child {
                // border-bottom: none;
            }
        }
    }
</style>
